//font family

@font-face {
    font-family: 'SF Pro Text';
    src: url('../fonts/SF-Pro-Text-Regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../fonts/SF-Pro-Text-Medium.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../fonts/SF-Pro-Text-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../fonts/SF-Pro-Text-Black.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
}

*:not(.as-preview-frame *) {
    font-family: "SF Pro Text", sans-serif;
}

[data-lock-scrolling],
[data-lock-scrolling-wrapper] {
    overflow: hidden;
}

body {
    font-family: 'SF Pro Text';
    background-color: $white !important;
    color: $textDefault !important;
}

p {
    color: $textSubdued;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
}

//class common
.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.ps-1 {
    padding-left: 5px;
}

.m-0 {
    margin: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-4 {
    margin-bottom: 24px;
}

.my-2 {
    margin: 10px 0;
}

.my-4 {
    margin: 20px 0;
}

.mt-2 {
    padding-top: 12px;
}

.mt-4 {
    margin-top: 20px;
}

.pb-4 {
    padding-bottom: 24px !important;
}

.pb-5 {
    padding-bottom: 40px !important;
}

.mx-20 {
    max-width: 20px;
}

.mx-125 {
    .Polaris-Connected {
        max-width: 125px;
    }
}

.d-block {
    display: block !important;
}

.mx-auto {
    margin: 0 auto;
}

.cursor-pointer {
    cursor: pointer;
}

.textdisabled {
    color: $textSubdued;
}

.Polaris-Text--bold {
    font-weight: 500 !important;
}

//dovider
.Polaris-Divider {
    margin: 20px 0;
}

.Polaris-Link {
    color: $baseInteractive;
}

//page
.Polaris-Page--fullWidth {
    padding: 16px !important;

    .Polaris-Page__Content {
        padding: 0 !important;
    }
}

//.Polaris-Modal-Dialog__Modal
@media (max-width:767px) {
    .Polaris-Modal-Dialog__Modal {
        top: 50% !important;
        bottom: unset !important;
        left: 50% !important;
        max-width: 90%;
        border-radius: 8px;
        transform: translate(-50%, -50%);
    }
}

//table
.Polaris-IndexTable {

    .Polaris-IndexTable__TableHeading {
        padding: 10px 15px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 133.333% */
    }

    tbody {
        td {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            padding: 10px 16px;
        }
    }

    .Polaris-IndexTable__TableRow {
        border-bottom: $border;
    }
}

// buttongroup
.btn_grp {
    .Polaris-ButtonGroup {
        button {
            border: 0 !important;
            padding: 0 !important;
            background-color: transparent !important;
            box-shadow: none;

            &:hover {
                background-color: transparent !important;
            }
        }
    }
}

.Polaris-Button.Polaris-Button--disabled {
    background-color: transparent;
}

//disables field
.Polaris-TextField__Input:disabled {
    border-color: #AEB4B9;
    background-color: #FFF;
    border-radius: 4px;
}

// switch toggle
.switch {
    .Polaris-Choice__Control {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 20px;

        input {
            opacity: 0;
            width: 0 !important;
            height: 0 !important;
        }

        .Polaris-Checkbox {
            @include width(100%, 100%);
            display: block;

            .Polaris-Checkbox__Icon {
                display: none;
            }
        }

        .Polaris-Checkbox__Backdrop {
            position: absolute;
            cursor: pointer;
            border: 0;
            top: 0;
            border-radius: 34px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #E1E6EF;
            -webkit-transition: .4s;
            transition: .4s;

            &::before {
                position: absolute;
                content: "";
                height: 15px;
                opacity: 1;
                transform: none;
                width: 15px;
                left: 4px;
                top: 2.9px;
                background-color: $white;
                -webkit-transition: .4s;
                border-radius: 50%;
                transition: .4s;
            }
        }

        input:checked+.Polaris-Checkbox__Backdrop {
            background-color: $primary;
        }

        input:focus+.Polaris-Checkbox__Backdrop {
            box-shadow: 0 0 1px $primary;
        }

        input:checked+.Polaris-Checkbox__Backdrop:before {
            -webkit-transform: translateX(14px);
            -ms-transform: translateX(14px);
            transform: translateX(14px);
        }
    }
}

.top_head_nav {
    padding: 0 16px 16px;
    border-bottom: $border;
    margin: 0 -16px 16px;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    background-color: #fff;
    z-index: 99;
    padding-top: 10px;
}

.upsell_tab_wrapper{
    border-bottom: $border;
    margin: 0 16px 16px 16px;

    .Polaris-Tabs__Tab--active:not([aria-disabled='true']):hover, .Polaris-Tabs__Tab--active:not([aria-disabled='true']):focus{
        background: transparent;
    }

    .Polaris-Tabs__Tab{
        position: relative;
        .Polaris-HorizontalStack{
            padding: 16px 16px 13px 16px;
        }
        .Polaris-Text--root{
            color: #6D7175;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
    .Polaris-Tabs__Tab--active{
        .Polaris-Text--root{
            color: #000;
        }
        background: transparent;
        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 100%;
            top: 37px;
            background-color: #008060;
        }
    }
}

.box_wrap {
    padding: 20px;
    margin: 0 16px;
    border: $border;
    border-radius: 4px;
}

//.Polaris-Button--plain svg
.Polaris-Button--plain svg {
    fill: #616a75;
}

.variant_modal {
    .nqqGC {
        .EH936 {
            display: none;
        }
    }
}

.Polaris-Button.Polaris-Button--disabled {
    border-color: var(--p-color-border-disabled) !important;
    background: var(--p-color-bg-disabled) !important;
    border: var(--p-border-width-1) solid var(--p-color-border-strong) !important;
}

button.Polaris-Button.Polaris-Button--primary.Polaris-Button--disabled {
    opacity: 0.8;
    color: $white;
    background: var(--p-color-bg-primary) !important;
}

// product modal style
.product_list_main_wrap {
    margin: 15px -20px 0;

    .product_list {
        height: 31.25rem;
        // max-height: inherit;
        max-height: 430px;
        overflow-y: auto;
        @include customscroll();

        .product_list_col {
            &:has(.Polaris-Choice--disabled){
                opacity: 0.5;
            }
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 8px 16px 8px 42px;
            height: 100%;
            max-height: 57px;
            // padding: 15px 20px 15px 38px;
            border-bottom: 1px solid #ebebeb;

            &:first-child {
                border-top: 1px solid #ebebeb;
            }

            .product_info_wrap {
                display: flex;
                align-items: center;
                gap: 12px;

                .Polaris-Thumbnail {
                    width: 40px;
                    height: 40px;
                }
                p{
                    color: #303030;
                }
            }

            .checkbox_wrap {
                .Polaris-Choice {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    // &.Polaris-Choice--disabled{
                    //     // If the label has the 'disable' class, adjust the opacity of the outer div
                    //       & ~ .checkbox_wrap {
                    //         opacity: 0.5; // Reduce opacity of the outer div
                    //       }
                    //     // opacity: 0.9;
                    //   }

                    .Polaris-Choice__Control {
                        top: 18px;
                        // top: 25px;
                        position: absolute;
                        left: 20px;
                        .Polaris-Checkbox__Backdrop::before{
                            background-color: #000;
                        }
                    }
                }
            }
        }
    }

    .product_selected_wrap {
        padding: 10px 20px 0;

        p {
            font-weight: 600;
        }

        ;
    }
}


button.Polaris-Button.Polaris-Button--primary.Polaris-Button--disabled.Polaris-Button--destructive {
    background: var(--pc-button-color) !important;
}

.login_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .login_form {
        width: 500px;
        margin: 0 auto;
    }

}

.toaster_main_wrap {
    .Polaris-Frame {
        background-color: transparent;
        min-height: auto;
    }
}

.chart_wrap {
    .datepicker {
        text-align: end;
    }
    .chart_icon{
        display: flex;
        align-items: center;
        gap: 10px;
        .Polaris-Icon{
            margin: 0;
        }
    }
}