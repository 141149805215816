.main_layout_page {
    // .Polaris-Page--fullWidth {
    //     // height: 100vh;
    // }

    .Polaris-Page__Content {
        height: 100%;

        .Polaris-IndexTable {
            height: calc(100% - 70px);
            border: $border;
            border-radius: 4px;
        }
    }
}

.steps_funnel {
    padding: 16px;
    display: flex;

    li {
        flex: 1;
        text-align: center;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 50%;
            top: 16px;
            left: 0;
            height: 2px;
            background: #DCDCDC;
        }

        &::after {
            content: '';
            position: absolute;
            width: 50%;
            top: 16px;
            right: 0;
            height: 2px;
            background: #DCDCDC;
        }

        &:first-child {
            &::before {
                content: none;
            }
        }

        &:last-child {
            &::after {
                content: none;
            }
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            @include width(32px, 32px);
            max-width: 32px;
            background-color: #E1E3E5;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            @include font(16px, 400, 16px, $textDefault);

            svg {
                fill: $white;
            }
        }

        h5 {
            @include font(16px, 400, 20px, $textSubdued );
            margin-top: 16px;
        }

        &.active {
            &::after {
                right: -20px;
                background-color: $primary;
            }

            &::before {
                left: -20px;
                background-color: $primary;
            }

            span {
                background-color: $primary;
                color: $white;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    padding: 20px;
                    border: 2px solid $primary;
                }
            }

            h5 {
                color: $textDefault;
                font-weight: 600;
            }
        }

        span.complete_step {
            &::after {
                content: none;
            }

            span {
                &::after {
                    content: none;
                }
            }
        }
    }
}

.step_content {
    height: calc(100% - 200px);

    @media (max-width:960px) {
        height: calc(100% - 210px);
    }

    .select_container {
        .Polaris-Link {
            .Polaris-Icon {
                svg {
                    fill: $baseInteractive;
                }
            }
        }
    }
}

.radio_box {
    padding: 20px;
    border: $border;
    border-radius: 4px;
    position: relative;
    text-align: center;

    .Polaris-Choice__Control {
        position: absolute;
        right: 0;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .Polaris-RadioButton {
        position: absolute;
        width: 18px;
        right: 8px;
        top: 0;
        height: 18px;
    }

    .Polaris-RadioButton__Backdrop {
        right: -8px;
        top: 8px;
    }

    .Polaris-Badge,
    img.icon_left {
        position: absolute;
        top: 8px;
        left: 8px;
    }

    label {
        padding-top: 20px;
        @include font(12px, 600, 16px, $textSubdued);
    }

    img {
        margin: 20px auto 0;
        display: block;
    }

    &.checked {
        background-color: #F1F8F5;
        border-color: $primary;

        img {
            filter: grayscale(100%) brightness(57%) sepia(300%) hue-rotate(70deg) saturate(520%);
        }

        img.icon_left {
            filter: none;
        }

        .Polaris-Choice__Label {
            color: $primary;
        }
    }
}

.trigger_step {
    .Polaris-Choice+.Polaris-Choice {
        padding-top: 20px;
    }
}

.main_searchbox {
    .Polaris-TextField {
        align-items: start;
    }

    .Polaris-TextField__Prefix {
        padding: 10px 0;
        order: 2;
    }

    .Polaris-TextField__VerticalContent {
        overflow: unset;
    }

    .Polaris-TextField__Prefix+.Polaris-TextField__Input {
        padding: 5px 12px;
    }
}

.funnel_rules {
    margin-top: 16px;
    .device_select {
        ul.Polaris-VerticalStack--listReset {
            display: flex !important;
            --pc-vertical-stack-order: row !important;
            margin-top: 10px;

            @media (max-width:752px) {
                --pc-vertical-stack-order: column !important;
                margin: 0;
            }

            li+li {
                margin-left: 60px;

                @media (max-width:752px) {
                    margin: 0;
                }
            }
        }
    }
}

.country_select,
.product_select {
    .Polaris-TextField__VerticalContent {
        overflow: unset;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 5px 0;
        padding: 8px;
        flex-wrap: wrap;

        @media (max-width:767px) {
            overflow-y: auto;
            // display: block;
            flex-wrap: nowrap;
        }

        .Polaris-TextField__Input {
            background-image: url('../images/icons/search.svg');
            background-repeat: no-repeat;
            background-position: 8px center;
            padding-left: 35px;
            min-height: auto;
        }

        >div {
            height: calc(100% - 32px);
            overflow: auto;
        }
    }

    .Polaris-Tag {
        margin-left: 8px;
        margin-bottom: 5px;
    }

    .Polaris-Tag__TagText {
        word-break: break-word;
        display: block;
        white-space: break-spaces;

        img {
            @include width(20px, 20px);
            border-radius: 50%;
        }

    }
}

.country_select {
    .Polaris-TextField__VerticalContent {
        flex-direction: column !important;
        flex-wrap: nowrap
    }
}

.country_list {
    @include customscroll;

    img {
        @include width(20px, 20px);
        border-radius: 50%;
    }
}

.set_offers {
    .discount_label {
        >div {
            display: flex;
            align-items: center;

            @media (max-width:1000px) {
                display: block;
            }
        }

        .Polaris-TextField {
            margin-left: 12px;

            @media (max-width:1000px) {
                margin-left: 0;
            }
        }
    }
}

.diff_locals {
    .tag_wrapper{
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .tag_item_wrap{
            cursor: pointer;
        }
    }
    .Polaris-Tag+.Polaris-Tag {
        margin-left: 8px;
        margin-bottom: 5px;
    }

    .Polaris-Scrollable--vertical {
        max-height: 300px;
    }
}

.multiselectlang {
    padding: 8px 0px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;
    @include customscroll;

    .Polaris-Choice__Control {
        display: none;
    }

    .Polaris-Box {
        padding: 2px 10px;
    }

    .Polaris-ResourceItem__ListItem+.Polaris-ResourceItem__ListItem {
        border: 0;
    }
}

.select_limit {
    >div {
        display: flex;
        align-items: center;

        @media (max-width:576px) {
            display: block;
        }

        .Polaris-Connected {
            margin: 0 12px;
            max-width: 80px;

            @media (max-width:576px) {
                margin: 0;
            }
        }

        .Polaris-Labelled__HelpText {
            margin: 0;

            .Polaris-Text--subdued {
                color: $textDefault;
            }
        }
    }
}

.add_downsellbtn {
    text-align: center;
    margin-top: -20px;

    .Polaris-ButtonGroup {
        justify-content: center;
    }
}

.add_another {
    text-align: center;
}