
//color
$white:#fff;
$primary:#008060;
$textDefault:#202223;
$textSubdued:#6D7175;
$baseInteractive:#2E72D2;


//border
$border: 1px solid #E1E3E5;




