@mixin font($fontsize, $fontweight, $lineheight, $color) {
    font-family: 'SF Pro Text', sans-serif;
    font-size: $fontsize;
    font-weight: $fontweight;
    line-height: $lineheight;
    color: $color;
}


@mixin width($width, $height){
    min-width: $width;
    min-height: $height;
}

@mixin customscroll{
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0px 8px 8px 0px;
        background: linear-gradient(180deg, #F8F8F8 0%, #FAFAFA 51.04%, #FAFAFA 100%);
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #C2C2C2;
        border-radius: 20px;
    }
}